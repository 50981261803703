import React from 'react'

const Copy = ({style, name}) => {
  return (
   
    <>
      <p style={style}>© 2024 - Everton Frankllin Pereira Silva</p>
    </>
  )
}

export default Copy
